@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
}

.page-item.active .page-link {
  background-color: aquamarine  !important;
  border-color: aquamarine  !important;
}

.page-link{
  color: black !important;
}